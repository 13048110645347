import React, { useState } from "react"
import Layout from "../components/panel/layout.js"
import Login from "../components/form/login"


const Loginpage = () => {
    return (
        <Layout>
            <Login />
        </Layout>

    )
}


export default Loginpage
