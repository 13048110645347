import * as React from 'react'
import { sgsidebar, sidebar_head, info_user, sg_message_sidebar,sg_icon_message,sg_logo_sidebar,sg_version, } from './index.module.css'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"


const Sidebar = () => {

    return(
        <div className={sgsidebar} >
            <div className={sidebar_head}>
                <Link to="/portable"><p className={info_user}><br />پرتابل پشتیبان گروپ</p></Link>
                <StaticImage
                    alt="supportwebs"
                    height={49}

                    src= "../images/icon/logo-final-doorsefid.png"
                    className={sg_logo_sidebar}
                />
            </div>
            <div className={sg_message_sidebar}>
                <StaticImage
                    alt="openai"
                    height={32}
                    src="../images/icon/ai.png"
                    className={sg_icon_message}

                />
                <Link to="/portable/chatgpt3" style={{textDecoration:`none`,color:`#000000`}}>چت جی پی تی (ChatGpt)</Link>
            </div>
            <span className={sg_version}>v 1.0.1</span>
        </div>
    )
}

export default Sidebar