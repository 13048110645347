import React, { useState } from "react"
import axios from "axios"

const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = async e => {
        e.preventDefault()

        try {
            const response = await axios.post(
                "http://localhost/admin/user/login",
                {
                    username: username,
                    password: password
                }
            )
            console.log(response.data)
        } catch (error) {
            console.log(error.response.data)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <label>
                user:
                <input type="username" value={username} onChange={e => setUsername(e.target.value)} required />
            </label>
            <label>
                Password:
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
            </label>
            <button type="submit">Login</button>
        </form>
    )
}

export default Login
