/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Sidebar from "../sidebar"
import {sg_content_panel,sg_panel_home} from "../index.module.css"

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
query  {
  site {
    siteMetadata {
      title
    }
  }
}

`)

    return (
        <>
            <div className={sg_panel_home}>
                <Sidebar />
                <div className={sg_content_panel}>
                    <Header siteTitle={data.site.siteMetadata.title || `Title`} />
                    <main style={{padding:`20px`}}>{children}</main>
                    <footer
                        style={{
                            marginTop: `var(--space-5)`,
                            fontSize: `var(--font-sm)`,
                        }}
                    >
                    </footer>
                </div>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
